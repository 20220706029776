import React from 'react';
import wall2 from "../../../../media/wallpaper/wall2.jpg";
import ImgOfSection from "./shared/ImgOfSection";

const Section1 = ({ t }) => {

  return (
    <section className="container-fluid">
      <div style={{ minHeight: "500px" }} className="row">
        <div style={{ zIndex: 200 }} className="col-12 position-absolute justify-content-start ffMontserrat py-4">
          JORDÀ
        </div>
        <div className="col-12 col-lg-4 bg-white text-dark order-1 order-lg-0">
          <div className="row h-100">
            <div style={{ textAlign: "justify" }} className="col-12 ffMontserrat my-5 align-self-center">
              <div className="row px-5 my-3">
                {t("The latest trends in contemporary kitchen furniture are displayed in our exhibition center.")}
                <br/>
                <br/>
                {t("Defined strokes, high quality materials and an innate obsession with detail, a good example of the quality and design that you can find in our store.")}
                <br/>
                <br/>
                {t("If you are looking for the latest in design and quality come to CUINES JORDÀ.")}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-8 bg-dark order-0 order-lg-1 text-dark">
          <ImgOfSection img={wall2} className="bg-black-60" inv title={t("ELEGANT")}/>
        </div>
      </div>
    </section>
  );
};

export default Section1;
