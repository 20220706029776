import React, { Component } from 'react';
import { traductorControl } from "../../wrappers/traductorControl";

function formValidationWrap(WhatComponentNeedsValidation) {
  const hoc = class extends Component {


    state = {
      errors: [],
      areErrorsVisible: false
    };
    errorsIncrement;

    setErrorsVisible = () => this.setState({ areErrorsVisible: true });

    errorMessages = (key, info = "") => {
      const { t } = this.props;
      const messages = {
        notEmpty: t("This field is required."),
        minChar: t("The field must contain at least") + " " + info + " " + t("characters"),
        maxChar: t("The field must contain a maximum of") + " " + info + " " + t("characters"),
        equal: t("Check does not match."),
        contain: t("The field must contain") + " '" + info + "'"
      };
      this.errorsIncrement.push(messages[key]);
    };
    static defaultProps = {
      title: "",
      placeholder: "",
      minChar: null,
      maxChar: null,
      notEmpty: false,
      equal: null,
      contain: null
    };

    componentWillMount() {
      this.comproveErrors();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if (prevProps !== this.props) {
        this.comproveErrors()
      }
    }

    componentWillUnmount() {
      this.sendErrors(true);
    }

    comproveErrors = () => {
      this.errorsIncrement = [];
      this.notEmpty();
      this.minChar();
      this.maxChar();
      this.contain();
      this.equal();
      this.setState({ errors: this.errorsIncrement });
      this.sendErrors();
    };

    sendErrors = (isCorrect = (this.errorsIncrement.length < 1)) => {
      const { captureErrors, name } = this.props;
      captureErrors && captureErrors(name, isCorrect)
    };

    notEmpty = () => {
      const { value, notEmpty } = this.props;
      if (notEmpty && value.length < 1) {
        this.errorMessages("notEmpty");
      }
    };

    minChar = () => {
      const { value, minChar } = this.props;
      if (minChar !== null && value.length < minChar)
        this.errorMessages("minChar", minChar)
    };

    maxChar = () => {
      const { value, maxChar } = this.props;
      if (maxChar !== null && value.length < maxChar)
        this.errorMessages("maxChar", maxChar)
    };

    equal = () => {
      const { value, equal } = this.props;
      if (equal !== null && equal !== value)
        this.errorMessages("equal")
    };

    contain = () => {
      const { value, contain } = this.props;
      if (contain !== null && value.indexOf(contain) < 0)
        this.errorMessages("contain", contain)
    };


    render() {
      const validation = {
        errors: this.state.errors,
        visible: this.state.areErrorsVisible
      };
      const { t } = this.props;

      return (
        <WhatComponentNeedsValidation
          validation={validation}
          setErrorsVisible={this.setErrorsVisible}
          t={t}
          {...this.props} />
      );
    }
  };

  hoc.displayName = (WhatComponentNeedsValidation.displayName || WhatComponentNeedsValidation.name);
  return traductorControl(hoc);
}

export { formValidationWrap };
