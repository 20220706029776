import React, { Component } from 'react';
import { formValidationWrap } from "./formValidationWrap";

class MyInput extends Component {

  render() {
    const { title, value, placeholder, onChange, name, className, validation, setErrorsVisible, t } = this.props;
    const showErrors = validation.errors.length > 0 && validation.visible;
    return (
      <div className={"row " + className}>
        <div className="col-12">
          <div className="row my-font-sz-08 my-font-wg-500 mb-1">{t(title)}</div>
          <div className="row">
            <textarea onBlur={setErrorsVisible} name={name}
                      className={"form-control form-control-lg" + (showErrors ? " border border-danger" : "")}
                      value={value} onChange={onChange} placeholder={t(placeholder)}
            />
          </div>
          <div className="row mb-3">
            {validation.visible &&
            <div className="d-flex flex-wrap my-font-sz-07 text-danger">
              {validation.errors.map((info, key) =>
                <div key={key} className="mr-1">{t(info)}</div>
              )}
            </div>}
          </div>
        </div>
      </div>
    );
  }
}

export default formValidationWrap(MyInput);
