import React, { Component } from 'react';
import { LocaleContext } from "./contexts/LocaleContext";
import { storageControl } from "./storageControl";

function localeProvControl(WhatComponentNeedsLocaleProvider) {
  const hoc = class extends Component {
    state = {
      locale: "es"
    };

    changeLocale = (event) => {
      this.changeStateLocale(event.target.id);
    };

    changeStateLocale = (newLocale) => {
      this.setState({ locale: newLocale });
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
      const { storage } = this.props;
      if (nextState.locale !== this.state.locale) {
        storage.setLocale(nextState.locale);
        return true;
      }
      return false;
    }

    componentWillMount() {
      const { storage } = this.props;
      const { locale } = this.state;
      if (storage.locale !== null) {
        this.changeStateLocale(storage.locale);
      } else {
        storage.setLocale(locale);
      }
    };

    render() {
      const { locale } = this.state;
      return (
        <LocaleContext.Provider value={{ "changeLocale": this.changeLocale, "locale": locale }}>
          <WhatComponentNeedsLocaleProvider {...this.props}/>
        </LocaleContext.Provider>
      );
    }
  };

  hoc.displayName = (WhatComponentNeedsLocaleProvider.displayName || WhatComponentNeedsLocaleProvider.name);
  return storageControl(hoc);
}

export { localeProvControl };
