import React, { Component } from 'react';
import posed from 'react-pose';
import next from '../../../media/icons/next.svg'
import back from '../../../media/icons/back.svg'
import home_slider_1 from './../../../media/wallpaper/wall1.jpg'
import home_slider_2 from './../../../media/wallpaper/wall2.jpg'
import home_slider_3 from './../../../media/wallpaper/wall3.jpg'


const MoveDiv = posed.div({
  hidden: {
    opacity: 0,
    applyAtEnd: { display: 'none' },
    transition: { duration: 1000 }
  },
  visible: {
    applyAtStart: { display: 'block' },
    opacity: 1,
    transition: { duration: 1000 }
  }
});


class Banner extends Component {

  static defaultProps = {
    size: "100%",
    imgs: [home_slider_1, home_slider_2, home_slider_3],
    arrows: false
  };

  state = {
    actualIndexImg: 0
  };

  myTimer;
  timeForChangingImg = 10000;
  imgsForCarousel = ((this.props.imgs.length > 0) ? this.props.imgs : [home_slider_1, home_slider_2, home_slider_3]);

  componentDidMount() {
    this.createTimer();
  }

  componentWillUnmount() {
    clearTimeout(this.myTimer);
  }

  createTimer = () => {
    this.myTimer = setTimeout(this.changeNextImg, this.timeForChangingImg);
  };

  restartTimer = () => {
    clearTimeout(this.myTimer);
    this.createTimer();
  };

  changeNextImg = () => {
    const actual = this.state.actualIndexImg;
    const imgsForCarousel = this.imgsForCarousel;
    (actual < (imgsForCarousel.length - 1)) ?
      this.setState({ actualIndexImg: (actual + 1) }) :
      this.setState({ actualIndexImg: 0 });
    this.restartTimer();
  };

  changePreviousImg = () => {
    const actual = this.state.actualIndexImg;
    const imgsForCarousel = this.imgsForCarousel;
    (actual === 0) ?
      this.setState({ actualIndexImg: (imgsForCarousel.length - 1) }) :
      this.setState({ actualIndexImg: (actual - 1) });
    this.restartTimer();
  };

  render() {
    const { actualIndexImg } = this.state;
    const { size, arrows } = this.props;
    const imgsForCarousel = this.imgsForCarousel;


    return (
      <div style={{ height: size }} className="container-fluid">

        {imgsForCarousel.map((img, index) => {
            return (
              <MoveDiv key={index}
                       style={{
                         backgroundImage: `url(${(imgsForCarousel[index])})`,
                         backgroundPosition: "center",
                         backgroundRepeat: "no-repeat",
                         backgroundSize: "cover",
                         height: size
                       }}
                       pose={(actualIndexImg === index) ? 'visible' : 'hidden'}
                       className="row w-100 position-absolute">

              </MoveDiv>)
          }
        )}

        <div style={{ height: size }} className="row w-100 position-absolute justify-content-between">
          {arrows &&
          <div className="col-auto d-none d-sm-flex align-items-center"
               style={{ cursor: "pointer" }}
               onClick={this.changePreviousImg}>
            <img width="50px" height="50px" src={back} alt=""/>
          </div>
          }
          <div className="col">
            {this.props.children}
          </div>
          {arrows &&
          <div className="col-auto d-none d-sm-flex align-items-center"
               style={{ cursor: "pointer" }}
               onClick={this.changeNextImg}>
            <img width="50px" height="50px" src={next} alt=""/>
          </div>
          }
        </div>
      </div>
    );
  }
}

export default Banner;
