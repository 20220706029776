import React, { Component } from 'react';
import { traductorControl } from "../../wrappers/traductorControl";

class MySubmit extends Component {
  static defaultProps = {
    className: "",
    children: null,
    type: "submit",
    onClick: null
  };

  render() {
    const { className, text, type, onClick, children, t, style = {} } = this.props;
    return (

      <button style={style} type={type} onClick={onClick !== null ? onClick : () => {
      }}
              className={"btn btn-secondary btn-block form-control form-control-lg " + className}>
        {children !== null ? children : t(text)}
      </button>
    );
  }
}

export default traductorControl(MySubmit);
