const AWS_ACCESS_KEY_ID = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
const AWS_SECRET_ACCESS_KEY = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const OWNER_EMAIL = process.env.REACT_APP_OWNER_EMAIL;
const ACCOUNT_EMAIL = process.env.REACT_APP_ACCOUNT_EMAIL;

const AWS = require('aws-sdk');

AWS.config.update({
  region: 'eu-west-1',
  accessKeyId: AWS_ACCESS_KEY_ID,
  secretAccessKey: AWS_SECRET_ACCESS_KEY,
});

const sendEmail = ({ title, html, to = OWNER_EMAIL }) => {
  const params = {
    Destination: {
      CcAddresses: [to],
      ToAddresses: [to]
    },
    Message: { /* required */
      Body: { /* required */
        Html: {
          Charset: "UTF-8",
          Data: html
        },
        Text: {
          Charset: "UTF-8",
          Data: ""
        }
      },
      Subject: {
        Charset: 'UTF-8',
        Data: title
      }
    },
    Source: ACCOUNT_EMAIL, /* required */
  };
  return new AWS.SES({ apiVersion: '2010-12-01' }).sendEmail(params).promise();
};

export { sendEmail };
