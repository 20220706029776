import React, { Component } from 'react';

function storageControl(WhatComponentNeedsStorageControl) {
  const hoc = class extends Component {

    keys = {
      locale: "JORDA-locale",
    };

    saveLocale = (locale) => localStorage.setItem(this.keys.locale, locale);
    saveDate = (dateString) => localStorage.setItem(this.keys.dateOfBooking, dateString);
    saveContact = (contact) => localStorage.setItem(this.keys.contact, JSON.stringify(contact));

    letsGet = (key) => localStorage.getItem(key);

    render() {
      const keys = this.keys;
      const get = this.letsGet;
      const storage = {
        locale: get(keys.locale),
        setLocale: this.saveLocale,
      };

      return (
        <WhatComponentNeedsStorageControl storage={storage} {...this.props}/>
      );
    }
  };

  hoc.displayName = (WhatComponentNeedsStorageControl.displayName || WhatComponentNeedsStorageControl.name);
  return hoc;
}

export { storageControl };
