import React, { PureComponent } from 'react';
import Gallery from "react-photo-gallery";
import photos from "./gallery/photos";


class PhotoGallery extends PureComponent {

  componentWillMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <Gallery photos={photos} direction={"row"}/>
    );
  }
}

export default PhotoGallery;
