import React, { Component } from 'react';
import { ModalContext } from "./contexts/ModalContext";
import { asyncComponent } from "./asyncComponent";

const Modal = asyncComponent(() => import('./../shared/modal/Modal'));

function modalProvControl(WhatComponentNeedsModalProvider) {
  const hoc = class extends Component {
    state = {
      modalContent: null,
      to: null
    };

    closeModal = () => {
      this.changeModalContent(null)
    };

    changeModalContent = (newContent, to = null) => {
      this.setState({ modalContent: newContent, to: to })
    };

    render() {
      const { modalContent, to } = this.state;
      return (
        <ModalContext.Provider value={this.changeModalContent}>
          <Modal children={modalContent} closeModal={this.closeModal} to={to}/>
          <WhatComponentNeedsModalProvider {...this.props}/>
        </ModalContext.Provider>
      );
    }
  };

  hoc.displayName = (WhatComponentNeedsModalProvider.displayName || WhatComponentNeedsModalProvider.name);
  return hoc;
}

export { modalProvControl };
