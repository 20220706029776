import React from 'react';
import wall1 from "../../../../media/wallpaper/wall1.jpg";
import ImgOfSection from "./shared/ImgOfSection";

const Section2 = ({ t }) => {

  return (
    <section className="container-fluid">
      <div style={{ minHeight: "500px" }} className="row">
        <div style={{ zIndex: 200 }} className="col-12 position-absolute justify-content-start ffMontserrat py-4">
          JORDÀ
        </div>
        <div className="col-12 col-lg-8 text-dark">
          <ImgOfSection img={wall1} title={t("MODERN")}/>
        </div>
        <div className="col-12 col-lg-4 bg-white text-dark">
          <div className="row h-100">
            <div style={{ textAlign: "justify" }} className="col-12 ffMontserrat my-5 align-self-center">
              <div className="row px-5 my-3">
                {t("CUINES JORDÀ, a company dedicated to kitchen furniture, has been developing its activity as a specialist in the design and installation of kitchens since its foundation.")}
                <br/>
                <br/>
                {t("Highly qualified professionals, designers, interior designers, specialized installers, work tailored to each client to offer personalized projects and integral developments that transform each kitchen into a unique and exclusive space.")}
              </div>
            </div>
          </div>
        </div>
        <div style={{ zIndex: 200 }}
             className="col-12 d-flex align-self-end position-absolute justify-content-end ffMontserrat py-4">
          JORDÀ
        </div>
      </div>
    </section>
  );
};

export default Section2;
