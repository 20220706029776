import React, { PureComponent } from 'react';
import { traductorControl } from "../wrappers/traductorControl";
import { modalProvControl } from "../wrappers/modalProvControl";
import Section2 from "./home/sections/Section2";
import Section1 from "./home/sections/Section1";
// import AppDisponibility from "../../components/shared/app/AppDisponibility";
import './home/home.css'
// import logo from "../../media/logos/logo.png"
import Banner from "../shared/banner/Banner";


class Home extends PureComponent {

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        {/*<AppDisponibility/>*/}
        <Banner size="100vh" arrows>
          <div className="container-fluid h-100">
            <div className="row justify-content-center align-items-center h-100 text-white ffMontserrat" style={{fontSize: "50px"}}>
              <div style={{fontSize: "70px"}} className="ffMontserrat bg-white-70 text-dark px-5 py-2 d-none d-md-block">
                JORDÀ
              </div>
              <div style={{fontSize: "40px"}} className="ffMontserrat bg-white-70 text-dark px-5 py-2 d-md-none">
                JORDÀ
              </div>
              {/*<img style={{maxWidth: "400px", minWidth: "250px"}} src={logo} width="100%" alt=""/>*/}
            </div>
          </div>
        </Banner>
        <Section2 t={t}/>
        <Section1 t={t}/>
      </React.Fragment>
    );
  }
}

export default traductorControl(modalProvControl(Home));
