import React, { Component } from 'react';
import { formValidationWrap } from "./formValidationWrap";

class MyInput extends Component {

  render() {
    const { title, type, value, onChange, name, className, validation, setErrorsVisible, t } = this.props;
    const showErrors = validation.errors.length > 0 && validation.visible;
    return (
      <div className={"row " + className}>
        <div className="col-12">
          <div className="row mb-1">{t(title)}</div>
          <div className="row">
            <input onBlur={setErrorsVisible} name={name}
                   className={"form-control form-control-lg" + (showErrors ? " border border-danger" : "")}
                   type={type} value={value} onChange={onChange}
            />
          </div>
          <div className="row mb-3">
            {validation.visible &&
            <div className="d-flex flex-wrap text-danger">
              {validation.errors.map((info, key) =>
                <div key={key} className="mr-1">{info}</div>
              )}
            </div>}
          </div>
        </div>
      </div>
    );
  }
}

export default formValidationWrap(MyInput);
