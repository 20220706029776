import React, { Component } from 'react';
import { LocaleContext } from "./contexts/LocaleContext";

function localeConsControl(WhatComponentNeedsLocaleControl) {
  const hoc = class extends Component {

    render() {
      return (
        <LocaleContext.Consumer>
          {value =>
            <WhatComponentNeedsLocaleControl
              locale={value.locale}
              changeLocale={value.changeLocale}
              {...this.props}/>
          }
        </LocaleContext.Consumer>
      );
    }
  };

  hoc.displayName = (WhatComponentNeedsLocaleControl.displayName || WhatComponentNeedsLocaleControl.name);
  return hoc;
}

export { localeConsControl };
